import React from "react";

export const CustomCollabIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1412_2755)">
        <path
          d="M12.1782 45.875L13.2095 45.3437L14.5467 47.9392L13.5154 48.4705L12.1782 45.875Z"
          fill="white"
        />
        <path
          d="M3.84937 47.9238L5.18653 45.3283L6.21785 45.8597L4.88069 48.4552L3.84937 47.9238Z"
          fill="white"
        />
        <path
          d="M8.60132 49.9531L8.67431 47.0336L9.8344 47.0626L9.76141 49.9821L8.60132 49.9531Z"
          fill="white"
        />
        <path
          d="M36.7468 2.06738L37.7781 1.53606L39.1153 4.13156L38.084 4.66288L36.7468 2.06738Z"
          fill="white"
        />
        <path
          d="M45.0852 4.12402L46.4224 1.52853L47.4537 2.05985L46.1165 4.65535L45.0852 4.12402Z"
          fill="white"
        />
        <path
          d="M41.4736 2.90918L41.5466 -0.0103884L42.7067 0.0186139L42.6337 2.93818L41.4736 2.90918Z"
          fill="white"
        />
        <path
          d="M44.9438 41.8505C45.0212 41.8505 45.0985 41.8312 45.1662 41.7828L45.1275 38.8633L32.1055 38.8729L32.1441 41.8795C32.2118 41.9279 32.2891 41.9472 32.3762 41.9472L44.9438 41.8505Z"
          fill="white"
        />
        <path
          d="M30.926 43.155L31.0227 49.5452H46.5196L46.4229 43.0293L30.926 43.155Z"
          fill="white"
        />
        <path
          d="M46.2877 27.3204L44.5863 22.013C44.4896 21.8486 44.3059 21.752 44.1125 21.752C43.6775 21.752 43.2618 21.9743 43.0105 22.332C42.6624 22.941 42.5368 23.6564 42.6431 24.3525C42.7881 25.2419 43.1555 27.5041 43.3295 28.5482C43.3875 28.8769 43.4165 29.0799 43.4165 29.0895L43.4938 29.5439L43.0685 29.7179C42.7398 29.8726 42.4401 30.066 42.1597 30.2883C41.773 30.5977 41.454 30.965 41.2027 31.3904C40.961 31.8158 40.7289 32.2605 40.5163 32.7148L39.4625 32.2218C39.6269 31.8738 39.8009 31.5257 39.9846 31.197L31.6125 34.6967C31.6706 35.7891 32.1056 36.8235 32.8596 37.6162C32.8887 37.6452 32.9177 37.6839 32.9563 37.7129L44.4896 37.7032C44.5282 37.6452 44.5669 37.5776 44.6056 37.5196C44.9439 36.9975 45.234 36.5238 45.234 36.5141L45.2436 36.4948C45.3403 36.3401 45.4177 36.1758 45.466 36.0018L47.0998 29.9306L46.6164 28.4128L46.2877 27.3204Z"
          fill="white"
        />
        <path
          d="M48.4918 25.0585L40.9512 7.01903L33.8747 9.97726C33.4299 10.1609 32.9079 9.94826 32.7242 9.50356C32.6662 9.35855 32.6469 9.1942 32.6662 9.03952C32.7532 8.44981 32.8982 6.99002 32.4535 6.13929C31.6511 4.64084 29.7853 4.08012 28.2869 4.88252C26.7884 5.68492 26.2277 7.55073 27.0301 9.04919C27.4168 9.76458 28.0645 10.2963 28.8379 10.538C29.1666 10.6346 29.389 10.9247 29.4083 11.263C29.4276 11.6014 29.2246 11.9204 28.9153 12.0558L21.2683 15.2073C21.3747 15.333 21.4617 15.4684 21.5293 15.623C21.8677 16.3771 22.3317 17.4212 22.8151 18.4943C23.0761 19.055 23.3371 19.635 23.5692 20.1861C23.8205 20.7468 24.0525 21.2688 24.2556 21.7135C25.3286 19.635 27.8808 18.823 29.9593 19.8961C31.7478 20.8241 32.6469 22.8833 32.0862 24.8168V24.8264C32.0862 24.8458 32.0765 24.8651 32.0765 24.8845C31.9702 25.3002 31.8058 25.6965 31.5835 26.0736C31.5448 26.1412 31.5061 26.1992 31.4578 26.2669C31.0711 26.8276 30.5587 27.2916 29.9593 27.6107C29.1279 28.0457 28.0259 28.1037 27.0784 28.0264L29.6597 33.7882C29.7273 33.9332 29.766 34.0782 29.795 34.2232L30.462 33.9428L31.6125 33.4595L41.5796 29.2928C41.7826 29.1381 41.9953 28.9931 42.2177 28.8674C42.0533 27.891 41.6569 25.4742 41.5119 24.5364C41.3669 23.5697 41.5409 22.5836 42.034 21.7329L42.0436 21.7135C42.5173 20.9981 43.3197 20.5824 44.1705 20.6018C44.7892 20.6114 45.3596 20.9691 45.6399 21.5202L45.6593 21.5685L47.3607 26.8759L47.8344 26.6826C48.4628 26.4119 48.7528 25.6869 48.4918 25.0585Z"
          fill="white"
        />
        <path
          d="M19.2673 7.77259L19.1803 1.15039H3.68335L3.77036 7.89826L19.2673 7.77259Z"
          fill="white"
        />
        <path
          d="M5.24945 9.07802C5.17211 9.07802 5.09477 9.09735 5.0271 9.14569L5.06577 12.0653V12.1813C5.13344 12.2296 5.21078 12.2586 5.29779 12.2489L17.8365 12.1523L17.8461 12.1716H17.8655C17.9428 12.1716 18.0201 12.1523 18.0878 12.1039L18.0395 9.04902C17.9718 9.00068 17.8945 8.97168 17.8075 8.98134L5.24945 9.07802Z"
          fill="white"
        />
        <path
          d="M25.7347 27.8519C25.609 27.5715 25.638 27.2428 25.8217 26.9915C25.8217 26.9818 25.8313 26.9818 25.8313 26.9721C26.015 26.7208 26.334 26.5854 26.6434 26.6241C27.2331 26.6918 28.6929 26.8078 29.534 26.3341C30.2204 25.9474 30.7327 25.3093 30.9551 24.5553C31.2838 23.1535 30.5877 21.7131 29.2826 21.0847C27.7552 20.3499 25.9183 20.9977 25.1836 22.5251C25.116 22.6605 25.0579 22.8055 25.0193 22.9505C24.8839 23.3759 24.4392 23.6175 24.0042 23.4822C23.7722 23.4145 23.5885 23.2405 23.4918 23.0182C23.2501 22.4478 22.8828 21.5584 22.4767 20.5916C22.4767 20.5916 22.4767 20.582 22.4671 20.582C21.771 19.0255 20.959 17.2177 20.4562 16.0769C20.3982 15.9513 20.3016 15.8546 20.1759 15.8062C20.1179 15.7869 20.0599 15.7676 19.9922 15.7676C19.9245 15.7676 19.8472 15.7869 19.7892 15.8159L19.6829 15.8643L18.5518 16.367L9.8704 20.2533C9.38703 20.9977 8.73931 21.6164 7.96592 22.0514C8.13026 23.0278 8.52663 25.4447 8.67164 26.3824C8.82632 27.3492 8.64264 28.3352 8.1496 29.186L8.13993 29.2053C7.68556 29.8917 6.92183 30.3074 6.1001 30.3267C6.0711 30.3267 6.04209 30.3267 6.00342 30.3267C5.38471 30.3171 4.81433 29.9594 4.53397 29.4083L4.51464 29.36L2.63916 23.4919L2.12678 23.7239C1.50806 24.0042 1.22771 24.739 1.50806 25.3577L9.62871 43.5035L16.1832 40.5646C15.3808 39.9458 14.5011 39.0951 14.1821 38.138C13.4377 35.9242 14.6268 33.517 16.8406 32.7726C19.0545 32.0282 21.4617 33.2173 22.2061 35.4312C22.4671 36.2045 22.4961 37.0359 22.2931 37.8287C23.9462 37.0843 26.6337 35.8855 28.2289 35.1701C28.5866 35.0058 28.7509 34.5901 28.5866 34.2324L25.7347 27.8519Z"
          fill="white"
        />
        <path
          d="M3.72208 23.0274L5.60723 28.9149C5.70391 29.0792 5.88759 29.1759 6.08094 29.1759C6.51597 29.1759 6.93167 28.9535 7.17336 28.5958C7.52139 27.9868 7.64706 27.2714 7.53105 26.5753C7.34737 25.4056 6.75766 21.877 6.75766 21.8383L6.72866 21.6836L6.68032 21.3839L7.10569 21.2099C7.11536 21.2099 8.30445 20.6975 8.96184 19.5375C8.99084 19.4794 9.01984 19.4311 9.05851 19.3731L10.6246 18.6771L18.3779 15.2064C18.1749 14.5007 17.8075 13.853 17.3048 13.3213L5.5589 13.418C5.22054 13.94 4.93051 14.4137 4.93051 14.4234L4.92085 14.4427C4.82417 14.5974 4.74683 14.7617 4.69849 14.9358L3.0647 21.0069L3.36439 21.935L3.72208 23.0274Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1412_2755">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
