import React from 'react'

export const UiUxService2 = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1412_8121)">
<path d="M39.452 2.5918H19.4572C18.8542 2.5918 18.3652 3.08073 18.3652 3.68375V9.5893C19.7038 10.16 20.8298 11.1981 21.483 12.5429L23.1205 15.9143L28.561 15.5617C30.1862 15.4563 31.6354 16.4744 32.1302 17.9617C32.2455 18.0104 32.3587 18.0628 32.4689 18.1193V16.1013H39.452C40.055 16.1013 40.5439 15.6124 40.5439 15.0093V3.68375C40.5439 3.08073 40.0551 2.5918 39.452 2.5918ZM30.6045 9.93629C30.6555 9.66647 30.5768 9.37686 30.368 9.16795C30.0324 8.83229 29.4881 8.83229 29.1525 9.16795C28.8168 9.5036 28.8168 10.0477 29.1525 10.3835C29.3614 10.5924 29.651 10.6711 29.9209 10.62L32.3483 13.0474L27.842 11.1471C27.6865 11.0815 27.5855 10.9293 27.5855 10.7605V10.3265C27.5855 9.68299 27.3298 9.06573 26.8747 8.61055L25.7499 7.48572C25.6571 7.39291 25.6571 7.24235 25.7499 7.14954L27.1342 5.76526C27.227 5.67245 27.3775 5.67245 27.4704 5.76526L28.5952 6.89009C29.0503 7.34519 29.6675 7.60086 30.3112 7.60086H30.7452C30.9138 7.60086 31.066 7.70193 31.1316 7.85732L33.0318 12.3634L30.6045 9.93629Z" fill="white"/>
<path d="M11.8718 1.53125C9.77339 1.53125 8.07227 3.23229 8.07227 5.33076C8.07227 7.44084 9.78763 9.13027 11.8718 9.13027C13.9556 9.13027 15.6713 7.44084 15.6713 5.33076C15.6713 3.23229 13.9702 1.53125 11.8718 1.53125Z" fill="white"/>
<path d="M22.7095 26.7812H19.0576C19.9751 27.149 20.625 28.0468 20.625 29.0941V31.3487C20.625 32.0289 20.3507 32.6461 19.9071 33.0961L18.7972 40.9609C18.6275 42.164 19.4652 43.2768 20.6682 43.4466C21.8739 43.6163 22.9844 42.7766 23.1539 41.5757L24.8877 29.2884C25.0746 27.9642 24.0447 26.7812 22.7095 26.7812Z" fill="white"/>
<path d="M18.134 28.0716C14.8786 28.0716 14.7727 28.0716 13.3283 28.0716V16.6698C13.3283 15.3425 12.2523 14.2666 10.9251 14.2666H2.40319C1.07596 14.2665 0 15.3425 0 16.6698V29.9683C0 31.2957 1.07596 32.3715 2.40319 32.3715C3.02924 32.3715 7.48556 32.3715 8.13454 32.3715C8.13454 33.0442 8.13454 37.0871 8.13454 37.7567L1.64988 41.4428C1.12957 41.7386 0.947549 42.4 1.2433 42.9204C1.53914 43.4406 2.20052 43.6229 2.72101 43.3269L8.4056 40.0956V40.9324C8.4056 41.5308 8.89075 42.016 9.4892 42.016C10.0876 42.016 10.5728 41.5308 10.5728 40.9324V40.0956L13.5317 41.7776C13.7614 42.7472 14.6321 43.4688 15.6719 43.4688C16.8869 43.4688 17.8718 42.4839 17.8718 41.2689V33.8401H13.472V39.2507L10.8437 37.7568C10.8437 37.0873 10.8437 33.0442 10.8437 32.3716C13.4864 32.3716 11.7389 32.3716 18.134 32.3716C18.6988 32.3716 19.1566 31.9137 19.1566 31.349V29.0945C19.1567 28.5306 18.6986 28.0716 18.134 28.0716Z" fill="white"/>
<path d="M43.532 21.8641C41.9884 21.8641 34.5048 21.8641 33.1388 21.8641C33.1772 21.7131 33.1965 21.562 33.1965 21.411C33.1965 20.2268 32.0666 19.2697 30.6204 19.0514C30.6057 17.9824 29.7026 17.1791 28.6711 17.2465L22.0981 17.6725L19.9648 13.2804C19.2291 11.7659 17.6659 10.7871 15.982 10.7871C7.70132 10.7871 7.33807 10.7871 6.96726 10.7871C5.43576 10.7871 4.08497 11.5895 3.3125 12.7976H10.9256C13.0607 12.7976 14.7973 14.5348 14.7973 16.6693V26.6027H16.7732V24.801C17.2731 24.801 39.3228 24.801 39.5995 24.801V42.1138C39.5995 42.8619 40.2059 43.4683 40.954 43.4683C41.7022 43.4683 42.3086 42.8618 42.3086 42.1138V24.8011H43.532C44.343 24.8011 45.0005 24.1436 45.0005 23.3326C45.0005 22.5217 44.343 21.8641 43.532 21.8641ZM16.7732 21.8641C16.7732 20.9499 16.7732 16.1522 16.7732 15.1016L19.3382 20.3827C19.6626 21.0506 20.3598 21.4594 21.1059 21.4111L26.8044 21.0417C26.7492 21.3106 26.7537 21.5862 26.8244 21.8641C25.5249 21.8641 18.1872 21.8641 16.7732 21.8641Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_1412_8121">
<rect width="45" height="45" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}
