import React from "react";

export const UiUxService4 = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1412_8145)">
        <path
          d="M15.5547 38.7578V41.4219H11.6875C10.975 41.4219 10.3984 41.9984 10.3984 42.7109C10.3984 43.4234 10.975 44 11.6875 44H32.3125C33.025 44 33.6016 43.4234 33.6016 42.7109C33.6016 41.9984 33.025 41.4219 32.3125 41.4219H28.4453V38.7578H15.5547Z"
          fill="white"
        />
        <path
          d="M1.28906 36.1797H42.7109C43.4234 36.1797 44 35.6031 44 34.8906V6.53125C44 5.81874 43.4234 5.24219 42.7109 5.24219H40.0469V27.1562C40.0469 29.2887 38.3121 31.0234 36.1797 31.0234H7.82031C5.68786 31.0234 3.95312 29.2887 3.95312 27.1562V5.24219H1.28906C0.576555 5.24219 0 5.81874 0 6.53125V34.8906C0 35.6031 0.576555 36.1797 1.28906 36.1797Z"
          fill="white"
        />
        <path
          d="M29.9255 19.9858L26.6172 18.8818L27.7199 22.1914L29.9255 19.9858Z"
          fill="white"
        />
        <path
          d="M37.4688 1.28906C37.4688 0.576555 36.8922 0 36.1797 0H7.82031C7.1078 0 6.53125 0.576555 6.53125 1.28906V10.3984H37.4688V1.28906ZM11.6875 6.53125C10.9756 6.53125 10.3984 5.95409 10.3984 5.24219C10.3984 4.53011 10.9756 3.95312 11.6875 3.95312C12.3994 3.95312 12.9766 4.53011 12.9766 5.24219C12.9766 5.95409 12.3994 6.53125 11.6875 6.53125ZM16.8438 6.53125C16.1318 6.53125 15.5547 5.95409 15.5547 5.24219C15.5547 4.53011 16.1318 3.95312 16.8438 3.95312C17.5557 3.95312 18.1328 4.53011 18.1328 5.24219C18.1328 5.95409 17.5557 6.53125 16.8438 6.53125ZM22 6.53125C21.2881 6.53125 20.7109 5.95409 20.7109 5.24219C20.7109 4.53011 21.2881 3.95312 22 3.95312C22.7119 3.95312 23.2891 4.53011 23.2891 5.24219C23.2891 5.95409 22.7119 6.53125 22 6.53125Z"
          fill="white"
        />
        <path
          d="M6.53125 27.1562C6.53125 27.8688 7.1078 28.4453 7.82031 28.4453H36.1797C36.8922 28.4453 37.4688 27.8688 37.4688 27.1562V12.9766H6.53125V27.1562ZM23.6668 15.9324C24.0142 15.59 24.5253 15.4653 24.9861 15.6215L32.7204 18.1996C33.1434 18.3406 33.4657 18.6905 33.5664 19.1261C33.6696 19.5604 33.5412 20.0173 33.224 20.3333L31.5572 22L33.224 23.6668C33.7275 24.1703 33.7275 24.9861 33.224 25.4896C32.7204 25.9931 31.9046 25.9931 31.4011 25.4896L29.7344 23.8228L28.0676 25.4896C27.7569 25.8003 27.3041 25.9359 26.8592 25.8332C26.4261 25.73 26.0736 25.4103 25.9327 24.9861L23.3545 17.2517C23.201 16.7883 23.3218 16.2773 23.6668 15.9324ZM11.6875 15.5547H19.4219C20.1344 15.5547 20.7109 16.1312 20.7109 16.8438C20.7109 17.5563 20.1344 18.1328 19.4219 18.1328H11.6875C10.975 18.1328 10.3984 17.5563 10.3984 16.8438C10.3984 16.1312 10.975 15.5547 11.6875 15.5547ZM11.6875 20.7109H19.4219C20.1344 20.7109 20.7109 21.2875 20.7109 22C20.7109 22.7125 20.1344 23.2891 19.4219 23.2891H11.6875C10.975 23.2891 10.3984 22.7125 10.3984 22C10.3984 21.2875 10.975 20.7109 11.6875 20.7109Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1412_8145">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
