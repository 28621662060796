export const checksTypeOfEmployement = [
  {
    type: "top",
    title: "full-time",
    value: "fullTime",
    check: false,
  },
  {
    type: "top",
    title: "part-time",
    value: "partTime",
    check: false,
  },
  {
    type: "top",
    title: "remote",
    value: "remote",
    check: false,
  },
  {
    type: "top",
    title: "internship",
    value: "internship",
    check: false,
  },
  {
    type: "top",
    title: "contract",
    value: "contact",
    check: false,
  },
];

export const checksCategories = [
  {
    title: "design",
    value: "design",
    check: false,
  },
  {
    title: "sales",
    value: "sales",
    check: false,
  },
  {
    title: "marketing",
    value: "marketing",
    check: false,
  },
  {
    title: "business",
    value: "business",
    check: false,
  },
  {
    title: "human resource",
    value: "hr",
    check: false,
  },
  {
    title: "finance",
    value: "finance",
    check: false,
  },
  {
    title: "software engineer",
    value: "softwareEngineer",
    check: false,
  },
  {
    title: "technology",
    value: "tech",
    check: false,
  },
];
