import React from "react";

export const Webdev2 = () => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1412_4404)">
        <path
          d="M36.1606 38.8933C36.1606 39.3814 36.5564 39.7772 37.0446 39.7772H44.116C44.3504 39.7772 44.5752 39.684 44.7411 39.5183C44.9068 39.3525 45 39.1277 45 38.8932V38.1699H36.1606V38.8933Z"
          fill="white"
        />
        <path
          d="M44.116 23.7061H37.0446C36.5564 23.7061 36.1606 24.1018 36.1606 24.59V25.3132H44.9999V24.59C44.9999 24.1018 44.6041 23.7061 44.116 23.7061Z"
          fill="white"
        />
        <path
          d="M18.4819 29.3306H27.3212H34.5534V26.9199H18.4819V29.3306Z"
          fill="white"
        />
        <path
          d="M36.9642 7.63438C36.9642 6.30302 35.8849 5.22363 34.5535 5.22363H10.4464C9.11503 5.22363 8.03564 6.30293 8.03564 7.63438V12.4558H14.8547C16.8571 12.458 18.4799 14.0807 18.4821 16.0831V25.313H34.5535V24.5897C34.558 23.2484 35.6236 22.1515 36.9642 22.1083V7.63438Z"
          fill="white"
        />
        <path
          d="M26.5177 30.9375H18.4819V34.1517H26.5177V30.9375Z"
          fill="white"
        />
        <path
          d="M44.9999 26.9199H36.1606V36.5628H44.9999V26.9199Z"
          fill="white"
        />
        <path
          d="M0 37.7567C0.000878906 38.872 0.904834 39.776 2.02017 39.7769H14.8548C15.9702 39.776 16.8741 38.872 16.875 37.7567V37.3662H0V37.7567Z"
          fill="white"
        />
        <path
          d="M0 35.7596H16.875V34.9561V30.1346V18.0811H0V35.7596Z"
          fill="white"
        />
        <path
          d="M14.8548 14.0625H2.02017C0.904834 14.0634 0.000878906 14.9673 0 16.0827V16.4732H16.875V16.0827C16.8741 14.9673 15.9702 14.0634 14.8548 14.0625Z"
          fill="white"
        />
        <path
          d="M30.5355 35.7598H18.4819V37.3669H31.3391V36.5634C31.339 36.1196 30.9793 35.7598 30.5355 35.7598Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1412_4404">
          <rect width="45" height="45" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
