import React from "react";

export const FilterIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.235 0H1.76501C1.42306 0.000268305 1.08853 0.0994258 0.802037 0.285438C0.515542 0.471451 0.289394 0.736321 0.151039 1.0479C0.0126849 1.35948 -0.0319275 1.70438 0.0226168 2.04073C0.0771612 2.37708 0.228517 2.69043 0.458315 2.94274L6.29594 9.3396V15.3725C6.29599 15.4905 6.32939 15.606 6.39232 15.7059C6.45525 15.8058 6.54516 15.8859 6.65173 15.9372C6.73691 15.9791 6.8307 16.0006 6.92567 16C7.06879 15.9999 7.20761 15.9512 7.31925 15.8619L8.5 14.9208L10.4679 13.3521C10.5416 13.2934 10.601 13.2189 10.6419 13.1342C10.6828 13.0495 10.704 12.9567 10.7041 12.8627V9.3396L16.5417 2.94274C16.7715 2.69043 16.9228 2.37708 16.9774 2.04073C17.0319 1.70438 16.9873 1.35948 16.849 1.0479C16.7106 0.736321 16.4845 0.471451 16.198 0.285438C15.9115 0.0994258 15.5769 0.000268305 15.235 0Z"
        fill="#0E7789"
      />
    </svg>
  );
};
