import React from "react";

const CIcon = ({ height, width, color }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "19"}
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5055 12.6897C11.586 13.4253 10.2986 13.977 9.01125 13.977C6.06872 13.977 4.04573 11.7701 4.04573 8.82758C4.04573 5.88505 6.06872 3.86207 9.01125 3.86207C10.2986 3.86207 11.586 4.22988 12.5055 5.14942L13.0572 5.70115L15.9998 2.94253L15.2641 2.3908C13.609 0.919536 11.4021 0 9.01125 0C3.86183 0 -0.000244141 3.86207 -0.000244141 9.01149C-0.000244141 14.1609 3.86183 18.023 9.01125 18.023C11.4021 18.023 13.609 17.1034 15.2641 15.6322L15.9998 15.0805L13.0572 12.1379L12.5055 12.6897Z"
        fill={color ? color :"white"}
      />
      <path
        d="M8.82705 11.9544C10.4522 11.9544 11.7696 10.637 11.7696 9.01187C11.7696 7.38675 10.4522 6.06934 8.82705 6.06934C7.20194 6.06934 5.88452 7.38675 5.88452 9.01187C5.88452 10.637 7.20194 11.9544 8.82705 11.9544Z"
        fill={color ? color :"white"}
      />
    </svg>
  );
};

export default CIcon;
