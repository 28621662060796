import React from "react";

export const ClientUnderstandingIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_96_3663)">
        <path
          d="M39.7461 38.2812C42.7125 38.2812 45.1172 35.8765 45.1172 32.9102C45.1172 29.9438 42.7125 27.5391 39.7461 27.5391C36.7797 27.5391 34.375 29.9438 34.375 32.9102C34.375 35.8765 36.7797 38.2812 39.7461 38.2812Z"
          fill="white"
        />
        <path
          d="M45.3839 38.9971C43.9024 40.3701 41.9206 41.2106 39.7461 41.2106C37.5716 41.2106 35.5897 40.3701 34.1083 38.9971C31.3292 40.8333 29.4922 43.9853 29.4922 47.5583V48.5349C29.4922 49.3438 30.148 49.9997 30.957 49.9997H48.5352C49.3441 49.9997 50 49.3438 50 48.5349V47.5583C50 43.9853 48.163 40.8333 45.3839 38.9971Z"
          fill="white"
        />
        <path
          d="M10.2539 38.2812C13.2203 38.2812 15.625 35.8765 15.625 32.9102C15.625 29.9438 13.2203 27.5391 10.2539 27.5391C7.28753 27.5391 4.88281 29.9438 4.88281 32.9102C4.88281 35.8765 7.28753 38.2812 10.2539 38.2812Z"
          fill="white"
        />
        <path
          d="M15.8917 38.9971C14.4103 40.3701 12.4284 41.2106 10.2539 41.2106C8.07939 41.2106 6.09756 40.3701 4.61611 38.9971C1.83701 40.8333 0 43.9853 0 47.5583V48.5349C0 49.3438 0.655859 49.9997 1.46484 49.9997H19.043C19.852 49.9997 20.5078 49.3438 20.5078 48.5349V47.5583C20.5078 43.9853 18.6708 40.8333 15.8917 38.9971Z"
          fill="white"
        />
        <path
          d="M16.8192 19.4778C17.4913 23.385 20.904 26.3672 25 26.3672C28.3539 26.3672 31.3317 24.3391 32.6223 21.3566C33.003 21.4412 33.394 21.4844 33.7891 21.4844C36.7507 21.4844 39.1602 19.0749 39.1602 16.1133C39.1602 14.4372 38.3797 12.8845 37.0967 11.8809C37.1701 11.5086 37.207 11.1283 37.207 10.7422C37.207 7.70303 34.881 5.19678 31.9156 4.91006C31.4931 3.70127 30.7556 2.61318 29.7712 1.76738C28.445 0.627734 26.7506 0 25 0C21.4622 0 18.5024 2.5209 17.8227 5.86094C17.7737 5.85986 17.7247 5.85938 17.6758 5.85938C13.9064 5.85938 10.8398 8.92598 10.8398 12.6953C10.8398 16.1745 13.4528 19.0551 16.8192 19.4778ZM27.9297 11.7188C28.7387 11.7188 29.3945 12.3746 29.3945 13.1836C29.3945 13.9926 28.7387 14.6484 27.9297 14.6484C27.1207 14.6484 26.4648 13.9926 26.4648 13.1836C26.4648 12.3746 27.1207 11.7188 27.9297 11.7188ZM22.0703 11.7188C22.8793 11.7188 23.5352 12.3746 23.5352 13.1836C23.5352 13.9926 22.8793 14.6484 22.0703 14.6484C21.2613 14.6484 20.6055 13.9926 20.6055 13.1836C20.6055 12.3746 21.2613 11.7188 22.0703 11.7188Z"
          fill="white"
        />
        <path
          d="M31.8117 35.3513C31.5736 34.5791 31.4454 33.7592 31.4454 32.9099C31.4454 32.746 31.4502 32.5832 31.4597 32.4216H26.4649V29.2011C25.9836 29.2642 25.4945 29.2966 25.0001 29.2966C24.5081 29.2966 24.0188 29.2641 23.5353 29.2002V32.4216H18.5405C18.5499 32.5832 18.5548 32.746 18.5548 32.9099C18.5548 33.7592 18.4266 34.5791 18.1885 35.3513H31.8117Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_96_3663">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
