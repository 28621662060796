import React from "react";

export const CloudCompIcon = () => {
  return (
    <svg
      className="w-[50px] h-[50px]"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.5 31.25V32.8125H40.625V29.6875C40.625 28.8246 39.9254 28.125 39.0625 28.125H33.5938V26.5625H35.9375C40.2451 26.5625 43.75 23.0576 43.75 18.75C43.75 14.4424 40.2451 10.9375 35.9375 10.9375C35.7821 10.9375 35.6413 10.9838 35.4997 11.0259C34.1794 6.46915 29.9767 3.125 25 3.125C20.0233 3.125 15.8206 6.46915 14.5003 11.0259C14.3587 10.9838 14.2179 10.9375 14.0625 10.9375C9.75494 10.9375 6.25 14.4424 6.25 18.75C6.25 23.0576 9.75494 26.5625 14.0625 26.5625H16.4062V28.125H10.9375C10.0746 28.125 9.375 28.8246 9.375 29.6875V32.8125H12.5V31.25H17.9688C18.8316 31.25 19.5312 30.5504 19.5312 29.6875V26.5625H23.4375V32.8125H26.5625V26.5625H30.4688V29.6875C30.4688 30.5504 31.1684 31.25 32.0312 31.25H37.5Z"
        fill="white"
      />
      <path
        d="M17.1875 34.375H4.6875C3.82385 34.375 3.125 35.0739 3.125 35.9375V42.1875C3.125 43.0511 3.82385 43.75 4.6875 43.75H9.375C8.51135 43.75 7.8125 44.4489 7.8125 45.3125C7.8125 46.1761 8.51135 46.875 9.375 46.875H12.5C13.3636 46.875 14.0625 46.1761 14.0625 45.3125C14.0625 44.4489 13.3636 43.75 12.5 43.75H17.1875C18.0511 43.75 18.75 43.0511 18.75 42.1875V35.9375C18.75 35.0739 18.0511 34.375 17.1875 34.375Z"
        fill="white"
      />
      <path
        d="M45.3125 34.375H32.8125C31.9489 34.375 31.25 35.0739 31.25 35.9375V42.1875C31.25 43.0511 31.9489 43.75 32.8125 43.75H37.5C36.6364 43.75 35.9375 44.4489 35.9375 45.3125C35.9375 46.1761 36.6364 46.875 37.5 46.875H40.625C41.4886 46.875 42.1875 46.1761 42.1875 45.3125C42.1875 44.4489 41.4886 43.75 40.625 43.75H45.3125C46.1761 43.75 46.875 43.0511 46.875 42.1875V35.9375C46.875 35.0739 46.1761 34.375 45.3125 34.375Z"
        fill="white"
      />
      <path
        d="M28.125 34.375H21.875C21.0121 34.375 20.3125 35.0746 20.3125 35.9375V45.3125C20.3125 46.1754 21.0121 46.875 21.875 46.875H28.125C28.9879 46.875 29.6875 46.1754 29.6875 45.3125V35.9375C29.6875 35.0746 28.9879 34.375 28.125 34.375Z"
        fill="white"
      />
    </svg>
  );
};
