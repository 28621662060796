import { AIBg, ArVrBg, BlockchainBG, CaseStudyBg, ContactBg, CustomSoftBg, FaqBg, MobdevBg, PrductResearchBg, UiUxBg, WebBg,TestimonialsBG } from "../assets/images"

export   const landingPageDataMob={topHeading:'Our Services', midHeading:"MOBILE APP DEVELOPMENT",bottomHeading:"",backgroundImage: MobdevBg}
export   const landingPageDataWeb={topHeading:'Our Services', midHeading:"WEB DEVELOPMENT",bottomHeading:"",backgroundImage: WebBg}
export   const landingPageDataBlockchain={topHeading:'Our Services', midHeading:"BLOCKCHAIN DEVELOPMENT SOLUTIONS",bottomHeading:"",backgroundImage: BlockchainBG}
export   const landingPageDataAI={topHeading:'Our Services',midHeading:"Artificial Intelligence serivces", bottomHeading:"",backgroundImage: AIBg}
export   const landingPageDataArVr={topHeading:'Our Services',midHeading:"AR/VR service", bottomHeading:"",backgroundImage: ArVrBg}
export   const landingPageDataUiUx={topHeading:'Our Services',midHeading:"Ui/UX service", bottomHeading:"",backgroundImage: UiUxBg}
export   const landingPageDataProductResearch={topHeading:'Our Services',midHeading:"Product Research Services", bottomHeading:"",backgroundImage: PrductResearchBg}
export   const landingPageDataCaseStudy={topHeading:'Join Us',midHeading:"Case Studies", bottomHeading:"Home > Careers",backgroundImage: CaseStudyBg}
export   const landingPageDataCareers={topHeading:'Join Us',midHeading:"Careers", bottomHeading:"",backgroundImage: CaseStudyBg}
export   const landingPageDataFaqs={topHeading:'FAQs',midHeading:"Frequently asked questions", bottomHeading:"",backgroundImage: FaqBg}
export   const landingPageDataCustomSoft={topHeading:'Our Services',midHeading:"Custom Software Development", bottomHeading:"",backgroundImage: CustomSoftBg}
export   const landingPageDataContact={topHeading:'Contact',midHeading:"Contact us", bottomHeading:"",backgroundImage: ContactBg}
export   const landingPageDataTestimonials={topHeading:'Testimonials',midHeading:"What they say", bottomHeading:"",backgroundImage: TestimonialsBG}
export   const landingPageDataCaseStudies={topHeading:'Join Us',midHeading:"Case Studies", bottomHeading:"",backgroundImage: CaseStudyBg}
export   const landingPageDataServices={topHeading:'Our Expertise',midHeading:"Our Services", bottomHeading:"",backgroundImage: MobdevBg}
export   const landingPageDataPrivacyPolicy={topHeading:'Effective : June 1st 2024 ',midHeading:"Privacy Policy", bottomHeading:"Effective : June 1st 2024",backgroundImage: CaseStudyBg}
