import React from "react";

export const ERPIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93194 34.7957V39.1745C10.7122 39.6139 12.0304 41.2199 12.0304 43.1214C12.0304 45.3714 10.2047 47.1972 7.95466 47.1972C5.70466 47.1972 3.87891 45.3714 3.87891 43.1214C3.87891 41.2124 5.19709 39.6139 6.97739 39.1745V34.3942C6.97739 34.129 7.08345 33.8866 7.25769 33.6972L10.7122 30.2578L12.091 31.6366L8.93194 34.7957Z"
        fill="white"
      />
      <path
        d="M29.0985 44.3865C29.0985 46.6365 27.2728 48.4623 25.0228 48.4623C22.7728 48.4623 20.9395 46.6365 20.9395 44.3865C20.9395 42.4698 22.2728 40.8789 24.0455 40.4395V36.8486H26.0001V40.4395C27.7728 40.8789 29.0985 42.4698 29.0985 44.3865Z"
        fill="white"
      />
      <path
        d="M46.121 43.129C46.121 45.379 44.2953 47.2048 42.0453 47.2048C39.7953 47.2048 37.9695 45.379 37.9695 43.129C37.9695 41.2199 39.2877 39.6214 41.068 39.182V34.8032L37.9316 31.6745L39.318 30.2881L42.7422 33.7123C42.9165 33.9017 43.0226 34.1441 43.0226 34.4093V39.1896C44.8029 39.6138 46.121 41.2199 46.121 43.129Z"
        fill="white"
      />
      <path
        d="M30.9473 16.2127V17.2354H31.7654C32.0458 17.2354 32.2806 17.0081 32.2806 16.7202C32.2806 16.4399 32.0533 16.2051 31.7654 16.2051H30.9473V16.2127Z"
        fill="white"
      />
      <path
        d="M25.0233 16.2129H24.2051V17.2356H25.0233C25.3036 17.2356 25.5384 17.0083 25.5384 16.7205C25.5308 16.4402 25.3036 16.2129 25.0233 16.2129Z"
        fill="white"
      />
      <path
        d="M25.0228 7.88672C19.3258 7.88672 14.6895 12.5231 14.6895 18.2201C14.6895 23.917 19.3258 28.5534 25.0228 28.5534C30.7198 28.5534 35.3561 23.917 35.3561 18.2201C35.3561 12.5231 30.7198 7.88672 25.0228 7.88672ZM20.0304 17.2352C20.5682 17.2352 21.0076 17.6746 21.0076 18.2125C21.0076 18.7504 20.5682 19.1897 20.0304 19.1897H18.3485V20.2125H20.0304C20.5682 20.2125 21.0076 20.6519 21.0076 21.1897C21.0076 21.7276 20.5682 22.167 20.0304 22.167H17.3713C16.8334 22.167 16.394 21.7276 16.394 21.1897V15.2276C16.394 14.6897 16.8334 14.2504 17.3713 14.2504H20.0304C20.5682 14.2504 21.0076 14.6897 21.0076 15.2276C21.0076 15.7655 20.5682 16.2049 20.0304 16.2049H18.3485V17.2276H20.0304V17.2352ZM27.4925 21.2049C27.4849 21.7428 27.0531 22.167 26.5152 22.167C26.5076 22.167 26.5076 22.167 26.5001 22.167C25.9622 22.1594 25.5304 21.7201 25.5379 21.1746C25.5455 20.3488 24.8637 19.8413 24.2046 19.5382V21.1822C24.2046 21.7201 23.7652 22.1594 23.2273 22.1594C22.6895 22.1594 22.2501 21.7201 22.2501 21.1822V18.2049V15.2276C22.2501 14.6897 22.6895 14.2504 23.2273 14.2504H25.0228C26.3864 14.2504 27.4925 15.3564 27.4925 16.7201C27.4925 17.5458 27.0834 18.2807 26.4546 18.7276C27.0682 19.3413 27.5001 20.1519 27.4925 21.2049ZM34.2349 16.7276C34.2349 18.0913 33.1288 19.1973 31.7652 19.1973H30.947V21.1973C30.947 21.7352 30.5076 22.1746 29.9698 22.1746C29.4319 22.1746 28.9925 21.7352 28.9925 21.1973V18.2201V15.2428C28.9925 14.7049 29.4319 14.2655 29.9698 14.2655H31.7652C33.1288 14.2579 34.2349 15.364 34.2349 16.7276Z"
        fill="white"
      />
      <path
        d="M37.9393 26.9323C37.7272 26.6217 37.7196 26.2126 37.909 25.8868C38.6514 24.6368 39.212 23.2959 39.5605 21.902C39.6514 21.5383 39.9469 21.258 40.3181 21.1823L41.6969 20.9171V15.5156L40.3181 15.2504C39.9469 15.1747 39.6514 14.8944 39.5605 14.5307C39.2045 13.1368 38.6514 11.7959 37.909 10.5459C37.712 10.2201 37.7272 9.81105 37.9393 9.50044L38.7196 8.33378L34.9014 4.5156L33.7348 5.2959C33.4166 5.50802 33.0151 5.5156 32.6893 5.3262C31.4469 4.58378 30.106 4.02317 28.7045 3.67469C28.3408 3.58378 28.0605 3.28832 27.9848 2.91711L27.7196 1.5459H22.3181L22.0529 2.91711C21.9772 3.28832 21.6969 3.58378 21.3332 3.67469C19.9317 4.03075 18.5908 4.58378 17.3484 5.3262C17.0226 5.52317 16.6135 5.50802 16.3029 5.2959L15.1363 4.5156L11.3181 8.33378L12.0984 9.50044C12.3105 9.81105 12.3181 10.2201 12.1287 10.5459C11.3863 11.7883 10.8257 13.1292 10.4772 14.5307C10.3863 14.8944 10.0908 15.1747 9.71961 15.2504L8.34082 15.5156V20.9171L9.71961 21.1823C10.0908 21.258 10.3863 21.5383 10.4772 21.902C10.8332 23.3035 11.3863 24.6444 12.1287 25.8868C12.3257 26.2126 12.3105 26.6217 12.0984 26.9323L11.3181 28.0989L15.1363 31.9171L16.3029 31.1368C16.6135 30.9247 17.0226 30.9171 17.3484 31.1065C18.5908 31.8489 19.9317 32.4095 21.3332 32.758C21.6969 32.8489 21.9772 33.1444 22.0529 33.5156L22.3181 34.8868H27.7196L27.9848 33.5156C28.0605 33.1444 28.3408 32.8489 28.7045 32.758C30.106 32.402 31.4469 31.8489 32.6893 31.1065C33.0151 30.9095 33.4242 30.9247 33.7348 31.1368L34.9014 31.9171L38.7196 28.0989L37.9393 26.9323ZM25.0226 30.5004C18.2499 30.5004 12.7348 24.9853 12.7348 18.2126C12.7348 11.4398 18.2499 5.92469 25.0226 5.92469C31.7954 5.92469 37.3105 11.4398 37.3105 18.2126C37.3105 24.9929 31.7954 30.5004 25.0226 30.5004Z"
        fill="white"
      />
    </svg>
  );
};
