import React from "react";

export const MobExpertise = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.8125 1.5625H14.0625C9.74844 1.5625 6.25 5.06094 6.25 9.375V35.9375C6.25 40.2516 9.74844 43.75 14.0625 43.75H31.25V38.8891C29.3203 36.9156 28.125 34.2203 28.125 31.25C28.125 25.2188 33.0328 20.3125 39.0625 20.3125C39.5938 20.3125 40.1125 20.3641 40.625 20.4375V9.375C40.625 5.06094 37.1266 1.5625 32.8125 1.5625ZM22.1562 35.9375H14.0625C13.1984 35.9375 12.5 35.2375 12.5 34.375C12.5 33.5125 13.1984 32.8125 14.0625 32.8125H22.1562C23.0203 32.8125 23.7188 33.5125 23.7188 34.375C23.7188 35.2375 23.0203 35.9375 22.1562 35.9375ZM22.5312 28.125H14.0625C13.1984 28.125 12.5 27.425 12.5 26.5625C12.5 25.7 13.1984 25 14.0625 25H22.5312C23.3953 25 24.0938 25.7 24.0938 26.5625C24.0938 27.425 23.3953 28.125 22.5312 28.125ZM27.2656 20.3125H14.0625C13.1984 20.3125 12.5 19.6125 12.5 18.75C12.5 17.8875 13.1984 17.1875 14.0625 17.1875H27.2656C28.1297 17.1875 28.8281 17.8875 28.8281 18.75C28.8281 19.6125 28.1297 20.3125 27.2656 20.3125ZM32.8125 12.5H14.0625C13.1984 12.5 12.5 11.8 12.5 10.9375C12.5 10.075 13.1984 9.375 14.0625 9.375H32.8125C33.6766 9.375 34.375 10.075 34.375 10.9375C34.375 11.8 33.6766 12.5 32.8125 12.5Z"
        fill="white"
      />
      <path
        d="M39.0625 42.1873C37.3828 42.1873 35.7969 41.7967 34.375 41.1201V46.8717C34.375 48.1201 35.7656 48.8639 36.8047 48.1717L39.0625 46.6654L41.3203 48.1717C42.3594 48.8639 43.75 48.1201 43.75 46.8717V41.1201C42.3281 41.7967 40.7422 42.1873 39.0625 42.1873Z"
        fill="white"
      />
      <path
        d="M39.0625 39.0625C43.3772 39.0625 46.875 35.5647 46.875 31.25C46.875 26.9353 43.3772 23.4375 39.0625 23.4375C34.7478 23.4375 31.25 26.9353 31.25 31.25C31.25 35.5647 34.7478 39.0625 39.0625 39.0625Z"
        fill="white"
      />
    </svg>
  );
};
