import React from "react";

export const CustomExpertiseIcon = () => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1412_2747)">
        <path
          d="M24.943 10.9969L28.9407 8.09216C29.3899 7.7656 29.5776 7.18754 29.4061 6.65952C29.2347 6.13151 28.743 5.77428 28.1875 5.77428H23.2451L21.7186 1.07469C21.5472 0.546678 21.0555 0.189453 20.5 0.189453C19.9444 0.189453 19.4527 0.546678 19.2813 1.07469L17.7548 5.77428H12.8125C12.2569 5.77428 11.7652 6.13151 11.5938 6.65952C11.4224 7.18754 11.61 7.7656 12.0592 8.09216L16.0569 10.9969L14.5304 15.6971C14.359 16.2251 14.5467 16.8038 14.9958 17.1297C15.4463 17.4563 16.0519 17.4563 16.5023 17.1297L20.5 14.225L24.4976 17.1297C24.9429 17.4526 25.5602 17.4516 26.0041 17.1297C26.4533 16.8038 26.641 16.2251 26.4695 15.6971L24.943 10.9969Z"
          fill="white"
        />
        <path
          d="M11.5312 10.8701H9.47795C8.92116 10.8701 8.43069 10.5141 8.25927 9.98488L7.62489 8.03171C7.45348 7.50371 6.96175 7.14648 6.40621 7.14648C5.85067 7.14648 5.35894 7.50371 5.18752 8.03171L4.55315 9.98488C4.38173 10.5141 3.89125 10.8701 3.33446 10.8701H1.28121C0.725671 10.8701 0.23394 11.2273 0.0625214 11.7554C-0.108897 12.2834 0.0787932 12.8621 0.527974 13.188L2.1896 14.3942C2.64004 14.722 2.82647 15.2982 2.65505 15.8275C2.65505 15.8275 2.02285 17.7733 2.02069 17.78C1.64793 18.9273 3.01398 19.9261 3.99261 19.2126L5.65299 18.0058C6.10342 17.678 6.70902 17.678 7.15946 18.0058C7.15946 18.0058 8.81965 19.2125 8.81983 19.2126C9.78211 19.9121 11.1726 18.9532 10.7917 17.78L10.1574 15.8281C9.98596 15.2982 10.1724 14.722 10.6228 14.3948L12.2845 13.188C12.7336 12.8621 12.9213 12.2834 12.7499 11.7554C12.5785 11.2274 12.0867 10.8701 11.5312 10.8701Z"
          fill="white"
        />
        <path
          d="M30.8426 15.8275C30.8426 15.8275 30.2106 17.7726 30.2082 17.78C29.8357 18.9265 31.2052 19.9234 32.1801 19.2126L33.8405 18.0058C34.2909 17.678 34.8965 17.678 35.347 18.0058L37.0073 19.2126C37.9908 19.9296 39.3532 18.932 38.9792 17.78L38.3449 15.8281C38.1735 15.2982 38.3599 14.722 38.8103 14.3948L40.472 13.188C40.9211 12.8621 41.1088 12.2834 40.9374 11.7554C40.766 11.2274 40.2742 10.8701 39.7187 10.8701H37.6655C37.1087 10.8701 36.6182 10.5141 36.4468 9.98488L35.8124 8.03173C35.641 7.50371 35.1492 7.14648 34.5937 7.14648C34.0382 7.14648 33.5464 7.50371 33.375 8.03173L32.7407 9.98488C32.5692 10.5141 32.0788 10.8701 31.522 10.8701H29.4687C28.9132 10.8701 28.4214 11.2273 28.25 11.7554C28.0786 12.2834 28.2663 12.8621 28.7155 13.188L30.3771 14.3942C30.8275 14.722 31.014 15.2982 30.8426 15.8275Z"
          fill="white"
        />
        <path
          d="M22.4219 20.5H18.5781C16.4586 20.5 14.7344 22.2242 14.7344 24.3438V37.1562C14.7344 39.2758 16.4586 41 18.5781 41H22.4219C24.5414 41 26.2656 39.2758 26.2656 37.1562V24.3438C26.2656 22.2242 24.5414 20.5 22.4219 20.5Z"
          fill="white"
        />
        <path
          d="M37.1562 25.625H33.3125C31.1929 25.625 29.4688 27.3492 29.4688 29.4688V37.1562C29.4688 39.2758 31.1929 41 33.3125 41H37.1562C39.2758 41 41 39.2758 41 37.1562V29.4688C41 27.3492 39.2758 25.625 37.1562 25.625Z"
          fill="white"
        />
        <path
          d="M7.6875 25.625H3.84375C1.72418 25.625 0 27.3492 0 29.4688V37.1562C0 39.2758 1.72418 41 3.84375 41H7.6875C9.80707 41 11.5312 39.2758 11.5312 37.1562V29.4688C11.5312 27.3492 9.80707 25.625 7.6875 25.625Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1412_2747">
          <rect width="41" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
