import React from "react";

export const MobCustomizationIcon = () => {
  return (
    <svg
      width="53"
      height="53"
      viewBox="0 0 53 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.125 29.8125V31.8487C33.125 32.2106 32.8899 32.5306 32.5445 32.6389C32.0845 32.7832 31.6396 32.9721 31.2163 33.2029C30.8937 33.3789 30.4939 33.3213 30.2341 33.0615L28.9844 31.8117L27.9141 32.8821L27.9133 32.8829L26.8429 33.9531L28.0928 35.2029C28.3525 35.4627 28.4102 35.8625 28.2342 36.185C28.0034 36.6083 27.8144 37.0532 27.6701 37.5132C27.5619 37.8587 27.2419 38.0938 26.8799 38.0938H24.8438V41.4062H26.8799C27.2419 41.4062 27.5619 41.6413 27.6701 41.9868C27.8144 42.4468 28.0034 42.8917 28.2342 43.315C28.4102 43.6375 28.3525 44.0373 28.0928 44.2971L26.8429 45.5469L28.9844 47.6883L30.2341 46.4385C30.4939 46.1787 30.8937 46.1211 31.2163 46.2971C31.6396 46.5279 32.0845 46.7168 32.5445 46.8611C32.8899 46.9694 33.125 47.2894 33.125 47.6513V49.6875H36.4375V47.6513C36.4375 47.2894 36.6726 46.9694 37.018 46.8611C37.478 46.7168 37.9229 46.5279 38.3462 46.2971C38.6688 46.1211 39.0686 46.1787 39.3284 46.4385L40.5781 47.6883L42.7196 45.5469L41.4697 44.2971C41.21 44.0373 41.1523 43.6375 41.3283 43.315C41.5591 42.8917 41.7481 42.4468 41.8924 41.9868C42.0006 41.6413 42.3206 41.4062 42.6826 41.4062H44.7188V38.0938H42.6826C42.3206 38.0938 42.0006 37.8587 41.8924 37.5132C41.7481 37.0532 41.5591 36.6083 41.3283 36.185C41.1523 35.8625 41.21 35.4627 41.4697 35.2029L42.7196 33.9531L40.5781 31.8117L39.3284 33.0615C39.0686 33.3213 38.6688 33.3789 38.3462 33.2029C37.9229 32.9721 37.478 32.7832 37.018 32.6389C36.6726 32.5306 36.4375 32.2106 36.4375 31.8487V29.8125H33.125ZM41.4062 39.75C41.4062 43.4089 38.4401 46.375 34.7812 46.375C31.1224 46.375 28.1562 43.4089 28.1562 39.75C28.1562 36.0911 31.1224 33.125 34.7812 33.125C38.4385 33.1291 41.4021 36.0928 41.4062 39.75Z"
        fill="white"
      />
      <path
        d="M34.7812 41.4062C35.696 41.4062 36.4375 40.6647 36.4375 39.75C36.4375 38.8353 35.696 38.0938 34.7812 38.0938C33.8665 38.0938 33.125 38.8353 33.125 39.75C33.125 40.6647 33.8665 41.4062 34.7812 41.4062Z"
        fill="white"
      />
      <path
        d="M29.8125 39.75C29.8125 42.4942 32.0371 44.7188 34.7812 44.7188C37.5254 44.7188 39.75 42.4942 39.75 39.75C39.75 37.0058 37.5254 34.7812 34.7812 34.7812C32.0384 34.7844 29.8156 37.0071 29.8125 39.75ZM38.0938 39.75C38.0938 41.5794 36.6107 43.0625 34.7812 43.0625C32.9518 43.0625 31.4688 41.5794 31.4688 39.75C31.4688 37.9206 32.9518 36.4375 34.7812 36.4375C36.6099 36.4396 38.0917 37.9214 38.0938 39.75Z"
        fill="white"
      />
      <path
        d="M8.28125 48.8594C8.28177 49.3165 8.65225 49.687 9.10938 49.6875H31.4688V48.2377C31.305 48.1738 31.1431 48.1052 30.9832 48.0318L29.57 49.445C29.2466 49.7683 28.7223 49.7683 28.3988 49.445L25.0863 46.1325C24.7629 45.8091 24.7629 45.2848 25.0863 44.9613L26.5 43.5481C26.4267 43.3882 26.3581 43.2264 26.2941 43.0625H8.28125V48.8594Z"
        fill="white"
      />
      <path
        d="M32.2969 3.3125H9.10938C8.65225 3.31302 8.28177 3.6835 8.28125 4.14062V8.28125H33.125V4.14062C33.1245 3.6835 32.754 3.31302 32.2969 3.3125ZM16.5625 6.625C16.1052 6.625 15.7344 6.25421 15.7344 5.79688C15.7344 5.33954 16.1052 4.96875 16.5625 4.96875C17.0198 4.96875 17.3906 5.33954 17.3906 5.79688C17.3906 6.25421 17.0198 6.625 16.5625 6.625ZM24.8438 6.625H19.875C19.4177 6.625 19.0469 6.25421 19.0469 5.79688C19.0469 5.33954 19.4177 4.96875 19.875 4.96875H24.8438C25.3011 4.96875 25.6719 5.33954 25.6719 5.79688C25.6719 6.25421 25.3011 6.625 24.8438 6.625Z"
        fill="white"
      />
      <path
        d="M20.7031 19.875C21.6178 19.875 22.3594 19.1335 22.3594 18.2188C22.3594 17.304 21.6178 16.5625 20.7031 16.5625C19.7884 16.5625 19.0469 17.304 19.0469 18.2188C19.0469 19.1335 19.7884 19.875 20.7031 19.875Z"
        fill="white"
      />
      <path
        d="M20.7027 21.5312C19.8414 21.5353 19.0428 21.9823 18.5889 22.7142C19.9274 23.3453 21.4779 23.3453 22.8164 22.7142C22.3625 21.9823 21.5639 21.5353 20.7027 21.5312Z"
        fill="white"
      />
      <path
        d="M8.28125 41.4062H23.1875V37.2656C23.1875 36.8083 23.5583 36.4375 24.0156 36.4375H26.2936C26.3575 36.2737 26.4261 36.1118 26.4995 35.9519L25.0863 34.5387C24.7629 34.2153 24.7629 33.691 25.0863 33.3675L25.3289 33.125H22.3594C21.902 33.125 21.5312 32.7542 21.5312 32.2969V27.3281C21.5312 26.8708 21.902 26.5 22.3594 26.5H27.3281C27.7855 26.5 28.1562 26.8708 28.1562 27.3281V30.2977L28.3988 30.055C28.7222 29.7317 29.2465 29.7317 29.57 30.055L30.9832 31.4688C31.1431 31.3955 31.3049 31.3268 31.4688 31.2629V28.9844C31.4688 28.527 31.8395 28.1562 32.2969 28.1562H33.125V9.9375H8.28125V41.4062ZM20.7031 11.5938C24.362 11.5938 27.3281 14.5599 27.3281 18.2188C27.3281 21.8776 24.362 24.8438 20.7031 24.8438C17.0443 24.8438 14.0781 21.8776 14.0781 18.2188C14.0823 14.5615 17.0459 11.5979 20.7031 11.5938ZM13.25 27.3281C13.25 26.8708 13.6208 26.5 14.0781 26.5H19.0469C19.5042 26.5 19.875 26.8708 19.875 27.3281V32.2969C19.875 32.7542 19.5042 33.125 19.0469 33.125H14.0781C13.6208 33.125 13.25 32.7542 13.25 32.2969V27.3281ZM14.0781 34.7812H19.0469C19.5042 34.7812 19.875 35.152 19.875 35.6094C19.875 36.0667 19.5042 36.4375 19.0469 36.4375H14.0781C13.6208 36.4375 13.25 36.0667 13.25 35.6094C13.25 35.152 13.6208 34.7812 14.0781 34.7812ZM14.0781 38.0938H19.0469C19.5042 38.0938 19.875 38.4645 19.875 38.9219C19.875 39.3792 19.5042 39.75 19.0469 39.75H14.0781C13.6208 39.75 13.25 39.3792 13.25 38.9219C13.25 38.4645 13.6208 38.0938 14.0781 38.0938Z"
        fill="white"
      />
      <path d="M23.1875 28.1562H26.5V31.4688H23.1875V28.1562Z" fill="white" />
      <path
        d="M18.3912 20.5877C17.081 19.3108 17.054 17.2137 18.3309 15.9035C19.6077 14.5933 21.7048 14.5663 23.015 15.8432C24.3252 17.12 24.3523 19.2171 23.0754 20.5273C23.0555 20.5477 23.0354 20.5678 23.015 20.5877C23.4771 20.8992 23.8723 21.2999 24.1772 21.7662C26.1391 19.8475 26.1741 16.7017 24.2555 14.7398C22.3368 12.7778 19.191 12.7429 17.229 14.6615C15.2671 16.5802 15.2321 19.726 17.1508 21.688C17.1766 21.7144 17.2026 21.7404 17.229 21.7662C17.534 21.2999 17.9292 20.8992 18.3912 20.5877Z"
        fill="white"
      />
      <path
        d="M14.9062 28.1562H18.2188V31.4688H14.9062V28.1562Z"
        fill="white"
      />
    </svg>
  );
};
