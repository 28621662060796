import React from "react";

export const MonInnovatIcon = () => {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_96_5198)">
        <path
          d="M33.6455 1.46413V4.8071C35.2959 4.8071 36.6338 6.11872 36.6338 7.73679C36.6338 9.35485 35.2959 10.6665 33.6455 10.6665V14.0844C33.6455 14.8934 34.3145 15.5493 35.1396 15.5493H38.626C38.626 17.1674 39.9638 18.479 41.6143 18.479C43.2647 18.479 44.6025 17.1674 44.6025 15.5493H48.0124C48.9033 15.5493 49.5893 14.7905 49.4975 13.9216C48.7238 6.59206 42.7819 0.76667 35.3057 0.00807598C34.4195 -0.0818654 33.6455 0.590693 33.6455 1.46413Z"
          fill="white"
        />
        <path
          d="M10.1465 15.9607C12.2508 15.9607 14.0409 17.3059 14.6691 19.165H18.8623V15.2893C20.6674 15.2893 22.1307 13.8546 22.1307 12.085C22.1307 10.3152 20.6673 8.88066 18.8623 8.88066V3.54004C9.73849 3.54004 2.25314 10.4125 1.49512 19.165H5.62393C6.25206 17.3059 8.04214 15.9607 10.1465 15.9607Z"
          fill="white"
        />
        <path
          d="M27.5781 23.8342C25.773 23.8342 24.3097 22.3995 24.3097 20.6299H20.3564V24.7409C22.2528 25.3567 23.6248 27.1117 23.6248 29.1748C23.6248 31.2379 22.2528 32.9929 20.3564 33.6087V41.9922H26.4886C26.4886 38.7126 27.9126 35.585 30.4174 33.4111C34.0208 30.2839 36.2938 25.72 36.2938 20.6299H30.8464C30.8465 22.3996 29.3832 23.8342 27.5781 23.8342Z"
          fill="white"
        />
        <path
          d="M17.3678 32.3793C17.3678 31.5703 18.0368 30.9145 18.8619 30.9145C19.8403 30.9145 20.6362 30.1341 20.6362 29.175C20.6362 28.2159 19.8403 27.4355 18.8619 27.4355C18.0368 27.4355 17.3678 26.7797 17.3678 25.9707V22.0949H13.4145C12.5893 22.0949 11.9204 21.4391 11.9204 20.6301C11.9204 19.6709 11.1245 18.8906 10.1461 18.8906C9.16775 18.8906 8.37187 19.671 8.37187 20.6301C8.37187 21.4391 7.70289 22.0949 6.87773 22.0949H1.49414C1.88421 26.5916 4.05281 30.5874 7.30675 33.4113C9.81152 35.5852 11.2355 38.7127 11.2355 41.9924H17.3677L17.3678 32.3793Z"
          fill="white"
        />
        <path
          d="M11.292 46.582C11.292 48.4697 12.8529 50 14.7783 50H22.8942C24.7792 50 26.3227 48.5312 26.3789 46.6839L26.4326 44.9219H11.292V46.582Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_96_5198">
          <rect width="51" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
