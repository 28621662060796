import React from "react";

export const UIUXService = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.1875 20.3125V21.9375H22.7435L23.9265 25.1875H25.1875V26.8125H24.518L25.7181 30.1096L28.7097 20.6034L30.0625 18.5347V2.4375H21.9375V5.6875H25.1875V7.3125H21.9375V10.5625H25.1875V12.1875H21.9375V15.4375H25.1875V17.0625H21.9375V19.7226L22.152 20.3125H25.1875Z"
        fill="white"
      />
      <path
        d="M20.6062 35.0732C21.5845 35.6556 22.6504 36.0762 23.7628 36.3187L24.9158 32.6576L17.5496 12.4247L12.9688 14.092L20.6062 35.0732Z"
        fill="white"
      />
      <path
        d="M16.9954 10.8972L12.2041 4.85956L12.4145 12.5645L16.9954 10.8972Z"
        fill="white"
      />
      <path
        d="M25.9997 36.5625C27.5032 36.5616 28.989 36.2385 30.3571 35.6151L36.2242 23.725L37.0944 19.0604C36.9319 19.0767 36.7629 19.1043 36.5955 19.1043C35.8698 19.1071 35.1523 18.9507 34.4936 18.646C33.8349 18.3414 33.2511 17.896 32.7832 17.3412L30.1922 21.3054L25.3984 36.5332C25.5991 36.5446 25.7982 36.5625 25.9997 36.5625Z"
        fill="white"
      />
      <path
        d="M35.3278 17.238C37.4094 18.0741 39.9266 16.6863 40.9495 14.1383C41.9724 11.5903 40.1703 5.17319 40.1703 5.17319C40.1703 5.17319 34.4316 8.56456 33.4087 11.1117C32.3858 13.6589 33.2454 16.4019 35.3278 17.238Z"
        fill="white"
      />
      <path
        d="M49.205 30.0625C49.6817 27.3751 49.6817 24.6249 49.205 21.9375H37.4888C37.9531 23.2415 38.1895 24.6158 38.1875 26C38.1875 29.2323 36.9035 32.3323 34.6179 34.6179C32.3323 36.9035 29.2323 38.1875 26 38.1875C22.7677 38.1875 19.6677 36.9035 17.3821 34.6179C15.0965 32.3323 13.8125 29.2323 13.8125 26C13.8105 24.6158 14.0469 23.2415 14.5113 21.9375H2.795C2.31833 24.6249 2.31833 27.3751 2.795 30.0625H7.75938C8.23034 32.1787 9.06615 34.1968 10.2294 36.0262L6.71938 39.5362C8.27771 41.7771 10.2229 43.7223 12.4638 45.2806L15.9738 41.7706C17.8032 42.9338 19.8213 43.7697 21.9375 44.2406V49.205C24.6249 49.6817 27.3751 49.6817 30.0625 49.205V44.2406C32.1787 43.7697 34.1968 42.9338 36.0262 41.7706L39.5363 45.2806C41.7771 43.7223 43.7223 41.7771 45.2806 39.5362L41.7706 36.0262C42.9338 34.1968 43.7697 32.1787 44.2406 30.0625H49.205Z"
        fill="white"
      />
    </svg>
  );
};
