import React from "react";

export const Webdev3 = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1412_4380)">
        <path
          d="M33.7813 21.6519H34.9928C34.7309 19.3244 33.8262 17.211 32.4343 15.4709L31.5769 16.3283C31.3137 16.5915 30.9687 16.7231 30.6241 16.7231C30.2795 16.7231 29.9345 16.5915 29.6713 16.3283C29.1448 15.8019 29.1448 14.9491 29.6713 14.4227L30.5286 13.5653C28.7886 12.1734 26.6752 11.2686 24.3477 11.0068V12.2183C24.3477 12.963 23.7447 13.566 23 13.566C22.2553 13.566 21.6524 12.963 21.6524 12.2183V11.0068C19.3248 11.2686 17.2114 12.1734 15.4714 13.5653L16.3288 14.4227C16.8552 14.9491 16.8552 15.8019 16.3288 16.3283C16.0656 16.5915 15.7209 16.7231 15.3759 16.7231C15.0313 16.7231 14.6863 16.5915 14.4231 16.3283L13.5657 15.4709C12.1738 17.211 11.2691 19.3244 11.0073 21.6519H12.2188C12.9635 21.6519 13.5664 22.2549 13.5664 22.9996C13.5664 23.7443 12.9635 24.3472 12.2188 24.3472H10.9697C11.1835 26.2768 11.8299 28.114 12.9203 29.7379H33.0797C34.1705 28.1161 34.8169 26.2782 35.0303 24.3472H33.7813C33.0365 24.3472 32.4336 23.7443 32.4336 22.9996C32.4336 22.2549 33.0365 21.6519 33.7813 21.6519ZM28.0867 19.8185L26.6376 21.2676C26.89 21.7948 27.043 22.3773 27.043 22.9996C27.043 25.2288 25.2293 27.0425 23 27.0425C20.7708 27.0425 18.957 25.2288 18.957 22.9996C18.957 20.7703 20.7708 18.9566 23 18.9566C23.6223 18.9566 24.2048 19.1096 24.732 19.362L26.181 17.9129C26.7075 17.3864 27.5603 17.3864 28.0867 17.9129C28.6131 18.4393 28.6131 19.2921 28.0867 19.8185Z"
          fill="white"
        />
        <path
          d="M23 21.6523C22.2563 21.6523 21.6523 22.2563 21.6523 23C21.6523 23.7437 22.2563 24.3477 23 24.3477C23.7437 24.3477 24.3477 23.7437 24.3477 23C24.3477 22.2563 23.7437 21.6523 23 21.6523Z"
          fill="white"
        />
        <path
          d="M44.9801 18.9977L42.6856 18.4464C42.183 16.2536 41.3302 14.1915 40.143 12.2949L41.3078 10.3524C41.6264 9.8221 41.5422 9.14301 41.1053 8.70607L37.2939 4.89473C36.8556 4.45779 36.1804 4.37357 35.6473 4.69223L33.7047 5.85669C31.8085 4.66977 29.746 3.81696 27.5536 3.31404L27.0023 1.01987C26.8521 0.421143 26.314 0 25.6953 0H20.3047C19.686 0 19.1479 0.421143 18.9977 1.01987L18.4464 3.31439C16.2536 3.81696 14.1915 4.66977 12.2949 5.85704L10.3524 4.69223C9.8221 4.37357 9.14301 4.45779 8.70607 4.89473L4.89473 8.70607C4.45779 9.14301 4.37357 9.8221 4.69223 10.3524L5.85669 12.2949C4.66977 14.1915 3.81696 16.2536 3.31404 18.4464L1.01987 18.9977C0.421143 19.1479 0 19.686 0 20.3047V25.6953C0 26.314 0.421143 26.8521 1.01987 27.0023L3.31439 27.5536C3.81696 29.7464 4.66977 31.8085 5.85704 33.7051L4.69223 35.6476C4.37357 36.1779 4.45779 36.857 4.89473 37.2939L8.70607 41.1053C9.14195 41.5447 9.8221 41.625 10.3527 41.3078L12.2953 40.1433C14.1915 41.3302 16.254 42.183 18.4464 42.686L18.9977 44.9801C19.1479 45.5789 19.686 46 20.3047 46H25.6953C26.314 46 26.8521 45.5789 27.0023 44.9801L27.5536 42.6856C29.7464 42.183 31.8085 41.3302 33.7051 40.143L35.6476 41.3078C36.1793 41.6264 36.8556 41.5422 37.2939 41.1053L41.1053 37.2939C41.5422 36.857 41.6264 36.1779 41.3078 35.6473L40.1433 33.7047C41.3302 31.8085 42.183 29.746 42.686 27.5536L44.9801 27.0023C45.5789 26.8521 46 26.314 46 25.6953V20.3047C46 19.686 45.5789 19.1479 44.9801 18.9977ZM25.6953 37.8242H20.3047C19.56 37.8242 18.957 37.2213 18.957 36.4766C18.957 35.7318 19.56 35.1289 20.3047 35.1289H25.6953C26.44 35.1289 27.043 35.7318 27.043 36.4766C27.043 37.2213 26.44 37.8242 25.6953 37.8242ZM34.8513 31.8966C34.5972 32.235 34.1985 32.4336 33.7746 32.4336H12.2254C11.8015 32.4336 11.4028 32.235 11.1487 31.8952C9.20372 29.3094 8.17578 26.2337 8.17578 23C8.17578 14.826 14.826 8.17578 23 8.17578C31.174 8.17578 37.8242 14.826 37.8242 23C37.8242 26.2351 36.7963 29.3119 34.8513 31.8966Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1412_4380">
          <rect width="46" height="46" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
