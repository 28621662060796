import React from "react";

export const G2Icon = ({ height, width, color }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 292.000000 300.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)"
        fill={color ? color : "white"}
        stroke="none"
      >
        <path
          d="M1275 2975 c-486 -77 -894 -374 -1109 -810 -62 -126 -95 -220 -128
-367 -20 -88 -23 -131 -23 -298 0 -167 3 -210 23 -298 71 -316 187 -530 411
-753 283 -282 595 -420 981 -436 237 -9 461 30 659 115 91 40 222 114 269 152
l22 18 -162 280 -161 281 -63 -45 c-79 -57 -212 -119 -300 -141 -104 -26 -295
-23 -404 6 -459 121 -735 598 -604 1047 59 205 183 373 360 487 118 77 218
112 368 131 l89 11 144 299 c79 165 140 302 136 306 -31 29 -363 38 -508 15z"
        />
        <path
          d="M2175 2876 c-96 -24 -193 -91 -230 -160 l-20 -36 65 -65 65 -65 35
47 c59 80 157 119 234 93 73 -24 99 -102 52 -157 -13 -15 -70 -53 -126 -83
-174 -94 -248 -165 -290 -279 -20 -53 -38 -183 -32 -228 l3 -23 335 0 334 0 0
95 0 95 -220 0 c-147 0 -220 3 -220 10 0 23 32 70 62 93 18 14 82 51 142 83
175 94 228 154 242 275 13 115 -38 211 -143 266 -92 49 -192 63 -288 39z"
        />
        <path
          d="M1619 1398 l-186 -323 371 -3 371 -2 180 -315 c98 -173 182 -315 185
-315 3 0 88 142 189 316 157 272 182 319 173 338 -6 11 -89 156 -185 321
l-175 300 -368 3 -369 2 -186 -322z"
        />
      </g>
    </svg>
  );
};
