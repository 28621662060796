import React from "react";

const LinkedinIcon = ({ height, width, color }) => {
  return (
    <svg
      width={width || "16"}
      height={height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.87503 3.49286H1.853C1.61316 3.50733 1.3729 3.47219 1.14724 3.38964C0.921581 3.30709 0.715365 3.1789 0.541467 3.01308C0.36757 2.84726 0.229727 2.64737 0.136546 2.42589C0.0433647 2.20441 -0.00315334 1.96609 -0.000103299 1.72583C0.00294674 1.48556 0.0554992 1.24851 0.154272 1.02946C0.253046 0.810417 0.395919 0.614089 0.573969 0.452737C0.75202 0.291385 0.961425 0.168473 1.18911 0.0916787C1.41679 0.014884 1.65786 -0.0141451 1.89726 0.00640421C2.13785 -0.0110201 2.37945 0.0215208 2.60685 0.101981C2.83426 0.182441 3.04256 0.309079 3.21866 0.473934C3.39475 0.638789 3.53484 0.838294 3.6301 1.05991C3.72537 1.28152 3.77375 1.52044 3.77221 1.76166C3.77068 2.00287 3.71925 2.24116 3.62116 2.46154C3.52308 2.68192 3.38046 2.87962 3.20228 3.04222C3.02409 3.20482 2.8142 3.32879 2.58578 3.40634C2.35737 3.48389 2.11538 3.51335 1.87503 3.49286Z"
        fill={color ? color :"white"}
      />
      <path d="M3.44934 5.8418H0.321045V15.2267H3.44934V5.8418Z" fill={color ? color :"white"} />
      <path
        d="M11.6592 5.8418C11.1322 5.84324 10.6123 5.96381 10.1383 6.1945C9.66442 6.42519 9.24877 6.76004 8.92245 7.17401V5.8418H5.79468V15.2267H8.92245V10.9253C8.92245 10.5104 9.08721 10.1126 9.3805 9.81926C9.67378 9.52593 10.0716 9.36113 10.4863 9.36113C10.9011 9.36113 11.2989 9.52593 11.5922 9.81926C11.8854 10.1126 12.0502 10.5104 12.0502 10.9253V15.2267H15.178V9.36113C15.178 8.89897 15.087 8.44133 14.9101 8.01434C14.7333 7.58736 14.4741 7.19939 14.1474 6.87259C13.8206 6.54579 13.4327 6.28655 13.0058 6.10969C12.5789 5.93283 12.1213 5.8418 11.6592 5.8418Z"
        fill={color ? color :"white"}
      />
    </svg>
  );
};

export default LinkedinIcon;
